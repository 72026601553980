import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { GetSakayaInfoData, IGetUserPayload, IUpdateSakayaInfoPayload, UpdateDeliveryInfoPayload } from '../../../../presentation/redux/actions/interfaces/users.types';
import { UserResetPasswordPayload, UserUpdatePasswordPayload } from '../../../../presentation/redux/actions/users/user.actions';
import { IGetCustomerGroupsPayload, IGetCustomersPayload, IPauseOrResumeCustomerPayload, ICreateAddressPayload, IExportQRCodePayload, IGetCustomerHidePriceUploadHistoryPayload, UpdateCustomerHolidayPayload, GetCustomersData, ImportDeliveryHolidaysHistoryPayload, ImportDeliveryHolidaysHistoryData } from '../../../../presentation/redux/actions/interfaces/customers.types';
import { APIResponse } from '../../../../models/response/types';

const login = (email: string, password: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const loginResource = SitecoreResource.Customer.Login();
    const body = {
        email: email,
        password: password
    };
   
    return apiGateway.doPostRequest(loginResource, body);
};

const getUsers = (payload: IGetUserPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.GetUsers(payload);
   
    return apiGateway.doGetRequest(userResource);
};

export const getCustomers = (payload: IGetCustomersPayload): Promise<ResponseModel<APIResponse<GetCustomersData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const loginResource = SitecoreResource.Customer.GetCustomers(payload);
    return apiGateway.doGetRequest(loginResource);
};

const getCustomerHidePriceUploadHistory = (payload: IGetCustomerHidePriceUploadHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.GetCustomerHidePriceUploadHistory(payload);

    return apiGateway.doGetRequest(customerResource);
}

const getCustomer = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.GetCustomer(id);
   
    return apiGateway.doGetRequest(customerResource);
};

const resetPassword = (payload: UserResetPasswordPayload): Promise<ResponseModel<string>> => {

    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const passwordResource = SitecoreResource.Customer.ResetPassword();
    // const body = {
    //     email: email,
    // };
   
    return apiGateway.doPostRequest(passwordResource, payload);
};

const updateCustomerHidePrice = (payload: any): Promise<ResponseModel<{ success: boolean, data: any, errorMessage: any }>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.UpdateCustomerHidePrice();

    return apiGateway.doPutRequest(customerResource, payload);
}

const changePassword = (payload: any): Promise<ResponseModel<string>> => {

    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const passwordResource = SitecoreResource.Customer.ChangePassword();
    const body = {
        email: payload.email,
        oldPassword:payload.oldPassword,
        newPassword:payload.newPassword,
        confirmNewPassword: payload.newPassword
    };
   
    return apiGateway.doPostRequest(passwordResource, body);
};

const addUser = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.AddUser();
   
    return apiGateway.doPostRequest(userResource, payload);
};

const updateUser = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UpdateUser();
   
    return apiGateway.doPutRequest(userResource, payload);
};

const getUserDetail = (id: string = ''): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UserProfile('', id);
    return apiGateway.doGetRequest(userResource);
};

const deleteUser = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.DeleteUser(id);
   
    return apiGateway.doDeleteRequest(userResource);
};

export const getSakayaInfo = (): Promise<ResponseModel<APIResponse<GetSakayaInfoData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.SakayaInfo();
   
    return apiGateway.doGetRequest(userResource);
};

export const updateDeliveryInfo = (payload: UpdateDeliveryInfoPayload): Promise<ResponseModel<APIResponse<null>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UpdateDeliveryInfo();
    return apiGateway.doPutRequest(userResource, payload);
}

export const removeCustomerHoliday = (id: string): Promise<ResponseModel<APIResponse<null>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.RemoveCustomerHoliday(id);
    return apiGateway.doDeleteRequest(userResource);
}

export const updateCustomerHoliday = (payload: UpdateCustomerHolidayPayload): Promise<ResponseModel<APIResponse<null>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UpdateCustomerHoliday();
    return apiGateway.doPutRequest(userResource, payload);
}

export const importDeliveryHoliday = (payload: FormData): Promise<ResponseModel<APIResponse<{ errors: string[] }>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.ImportDeliveryHoliday();
    return apiGateway.doPostRequest(userResource, payload);
}

export const importDeliveryHolidaysHistory = (payload: ImportDeliveryHolidaysHistoryPayload): Promise<ResponseModel<APIResponse<ImportDeliveryHolidaysHistoryData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.ImportDeliveryHolidaysHistory(payload);
    return apiGateway.doGetRequest(userResource);
}

const updateSakayaInfo = (payload: IUpdateSakayaInfoPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UpdateSakayaInfo();
   
    return apiGateway.doPutRequest(userResource, payload);
};

const updatePassword = (payload: UserUpdatePasswordPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const userResource = SitecoreResource.Customer.UpdatePassword();
   
    return apiGateway.doPostRequest(userResource, payload);
};

const getCustomerGroups = (payload: IGetCustomerGroupsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.GetCustomerGroups(payload);
   
    return apiGateway.doGetRequest(customerResource);
};

const exportCustomers = (payload: IGetCustomersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.ExportCustomers(payload);
   
    return apiGateway.doGetRequest(customerResource);
};

const createAddress = (payload: ICreateAddressPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.CreateAddress(payload);
   
    return apiGateway.doPostRequest(customerResource);
};

export const pauseOrResumeCustomer = (payload: IPauseOrResumeCustomerPayload): Promise<ResponseModel<APIResponse<null>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.PauseOrResumeCustomer();
    return apiGateway.doPutRequest(customerResource, payload);
};

const importHorecaInformation = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.ImportHorecaInformation();
   
    return apiGateway.doPostRequest(customerResource, payload);
};

const importHidePrice = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.ImportHidePrice();

    return apiGateway.doPostRequest(customerResource, payload);
}

const exportQRCodes = (payload: IExportQRCodePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.ExportQRCodes(payload);
   
    return apiGateway.doGetRequest(customerResource);
};

const getHidePriceFile = (payload: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.GetCustomerHidePriceFile(payload);

    return apiGateway.doGetRequest(customerResource)
}

const getSakayas = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Customer.GetSakayas();

    return apiGateway.doGetRequest(customerResource)
}

export default {
    login,
    getUsers,
    getUserDetail,
    getSakayaInfo,
    updateSakayaInfo,
    updatePassword,
    addUser,
    updateUser,
    resetPassword,
    changePassword,
    getCustomers,
    getCustomerGroups,
    getCustomer,
    deleteUser,
    exportCustomers,
    createAddress,
    pauseOrResumeCustomer,
    importHorecaInformation,
    exportQRCodes,
    updateCustomerHidePrice,
    importHidePrice,
    getCustomerHidePriceUploadHistory,
    getHidePriceFile,
    getSakayas
};
