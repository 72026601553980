import {gql} from 'graphql-request';

export const getNotAssignPrefecturesQuery = () => {
    return gql`
    query {
      result:prefectures{
        id
        name
        areas{
          id
          name
        }
      }
    }`;
  }

 

  