import {
  IGetCustomerGroupsPayload,
  IGetCustomersPayload,
  IPauseOrResumeCustomerPayload,
  ICreateAddressPayload,
  IExportQRCodePayload,
  IUpdateCustomerHidePricePayload,
  IGetCustomerHidePriceUploadHistoryPayload,
} from "../../../presentation/redux/actions/interfaces/customers.types";
import {
  IGetUserPayload,
  IUpdateSakayaInfoPayload,
} from "../../../presentation/redux/actions/interfaces/users.types";
import {
  UserResetPasswordPayload,
  UserUpdatePasswordPayload,
} from "../../../presentation/redux/actions/users/user.actions";
import CustomerCloud from "./cloud";
import CustomerStorage from "./storage";
import CmsCloud from "./cms/index";

export default class CustomerRepository {
  loginUser = (email: string, password: string) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.login(email, password);
  };

  changePassword = (payload: any) => {
    return CustomerCloud.changePassword(payload);
  };

  /**
   *
   * @param token
   * @returns {Promise<ResponseModel<any>>}
   */
  saveToken = (token: any) => {
    return CustomerStorage.setAuthToken(token);
  };

  /**
   *
   * @param token
   * @returns {Promise<ResponseModel<any>>}
   */
  saveResetToken = (token: any) => {
    return CustomerStorage.saveResetToken(token);
  };

  saveRefreshToken = (token: any) => {
    return CustomerStorage.setRefreshToken(token);
  };

  saveUserProfile = (token: any) => {
    return CustomerStorage.setUserProfile(token);
  };

  getUserProfile = () => {
    return CustomerStorage.getUserProfile();
  };

  /**
   *
   *
   * @memberof UserRepository
   */
  removeToken = () => {
    return CustomerStorage.removeAuthToken();
  };

  /**
   *
   * @returns {Promise<ResponseModel<string>>}
   */
  getCustomerToken = () => CustomerStorage.getAuthToken();

  /**
   *
   * @param {string} token
   * @returns {Promise<ResponseModel<any>>}
   */
  setCustomerToken = (token: string) => CustomerStorage.setAuthToken(token);

  public getUsers = (payload: IGetUserPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getUsers(payload);
  };

  public getCustomers = (payload: IGetCustomersPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getCustomers(payload);
  };

  public getCustomerHidePriceUploadHistory = (
    payload: IGetCustomerHidePriceUploadHistoryPayload
  ) => {
    return CustomerCloud.getCustomerHidePriceUploadHistory(payload);
  };

  public getCustomer = (id: string) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getCustomer(id);
  };

  public addUser = (payload: any) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.addUser(payload);
  };

  public updateUser = (payload: any) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.updateUser(payload);
  };

  public getUserDetail = (id: string = "") => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getUserDetail(id);
  };

  public deleteUser = (id: string) => {
    return CustomerCloud.deleteUser(id);
  };

  public getSakayaInfoRepository = () => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getSakayaInfo();
  };

  public updateSakayaInfoRepository = (payload: IUpdateSakayaInfoPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.updateSakayaInfo(payload);
  };

  public updatePasswordRepository = (payload: UserUpdatePasswordPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.updatePassword(payload);
  };
  public getCustomerGroupsRepository = (payload: IGetCustomerGroupsPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.getCustomerGroups(payload);
  };

  public resetPasswordRepository = (payload: UserResetPasswordPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.resetPassword(payload);
  };

  public getSakayas = () => {
    return CustomerCloud.getSakayas();
  };

  public getNotAssignPrefecturesData = () => {
    return CmsCloud.getNotAssignPrefecturesData();
  };

  public getNotAssignStoreCategoriesData = () => {
    return CmsCloud.getNotAssignStoreCategoriesData();
  };

  public getNotAssignReasonsData = () => {
    return CmsCloud.getNotAssignReasonsData();
  };

  public getNotAssignStockStatusData = () => {
    return CmsCloud.getNotAssignStockStatusData();
  };

  public exportCustomers = (payload: IGetCustomersPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.exportCustomers(payload);
  };

  public pauseOrResumeCustomer = (payload: IPauseOrResumeCustomerPayload) => {
    // Consider call api or get data from localStorage
    return CustomerCloud.pauseOrResumeCustomer(payload);
  };

  public updateCustomerHidePrice = (
    payload: IUpdateCustomerHidePricePayload
  ) => {
    return CustomerCloud.updateCustomerHidePrice(payload);
  };

  public createAddress = (payload: ICreateAddressPayload) => {
    return CustomerCloud.createAddress(payload);
  };

  public importHorecaInformation = (payload: any) => {
    return CustomerCloud.importHorecaInformation(payload);
  };

  public importHidePrice = (payload: any) => {
    return CustomerCloud.importHidePrice(payload);
  };

  public exportQRCodes = (payload: IExportQRCodePayload) => {
    return CustomerCloud.exportQRCodes(payload);
  };

  public getHidePriceFile = (payload: string) => {
    return CustomerCloud.getHidePriceFile(payload);
  };
}
