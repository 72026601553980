import { getBaseConfig } from '../../config';

export const getConfig = () => {
    const mBaseConfig = getBaseConfig();
    if (mBaseConfig) {
        return {
            ...mBaseConfig,            
        };
    }
};

export const graphConfig = () => {
    return {
      endpoint: 'https://ap-northeast-1-cocacola-jp.cdn.hygraph.com/content/clhkkqz4840eo01uh4lzuag6a/master',
      domain: 'https://ap-northeast-1-cocacola-jp.cdn.hygraph.com/content/clhkkqz4840eo01uh4lzuag6a/master',
      accessToken:'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MTMzMjgxNTgsImF1ZCI6WyJodHRwczovL2FwaS1hcC1ub3J0aGVhc3QtMS1jb2NhY29sYS1qcC5oeWdyYXBoLmNvbS92Mi9jbGhra3F6NDg0MGVvMDF1aDRsenVhZzZhL21hc3RlciIsIm1hbmFnZW1lbnQtbmV4dC5ncmFwaGNtcy5jb20iXSwiaXNzIjoiaHR0cHM6Ly9tYW5hZ2VtZW50LWFwLW5vcnRoZWFzdC0xLWNvY2Fjb2xhLWpwLmh5Z3JhcGguY29tLyIsInN1YiI6IjMzOWJhYjA5LTNmZTEtNDEzMS05NDJlLTRjMjBhZDQ5MTRlYiIsImp0aSI6ImNsdjNiY2s0cTA0ZXYwN3lkY2psZzdrajYifQ.r1C8rD5jEIUv6aHvMgS2_DVKijuWBzbWOMqQaNqomwUPN8XuhKm3Zy8iJ3XoD6wlMNOk3czvlBXubgycrlg1OGgMdZA5Lo0kxVYfbv4wbtRcslhHHt0EpdIEaBDkQUA1smCOILeeycFbWq83LQRjCK6NU5qSvaptZWlgM4HrB279Vu6mRi9FK9J9uwwZhC72IX-sWYtSAKrpWbMfKzUxS3xe6UfCTLeympc1zF8ZxOZTeTYYkEfLpr4z3tnRDQrDXkLAhy6oNk4s7PHAEDKqxEQ0bitI8LtDn-WTf4zmvVafNYvCrhwIEHrIZgszUXWNLyOOZEGIO_WV81dN-ImC2SN20Ps5Z99ZcNjVDVILnoeK_toyVIF2o3oL9f4-h-1801B28rdoIYSsqqHQn2YSQaiWyWQ7lntX8rW5pTxnMufVVsotmhYnZxjAj23C6o-5hTanRvA4xbm2eTX3yJ2DeAhbL7TwxWT8e08PO07s-54UZA67DTu-SadP_Cn4elV1GMOGLuvvDfM5wuom8Cbx8rsqnM6FmY14M0c4qJ_7igmmcJzxo6Nb5Wn7LLmpLiGbnUTFFCn0649NpwxcVEeSWj_QEtF5EiNzFH1-4fzGJLjlssuRerG0i0dWkpGr7YXAZa2nHRcO-KcXKegqewwUxWmOkapftwHtJAzqUQnYx-M',
      googleApiKey: 'AIzaSyDe2Kk6i-VPLHmGq-0_RH7JYk1QlaxSYMsI',
      sitecoreApiKey: '7A3E0468-E733-4F81-AABE-2BC0E797E5E0',
    };
  };
  