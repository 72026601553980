import { IAction } from "../../../../shared/interfaces/common";
import {
  IExportQRCodePayload,
  IGetCustomerHidePriceUploadHistoryPayload,
  IGetCustomerPayload,
  IGetCustomersPayload,
  IPauseOrResumeCustomerPayload,
  IUpdateCustomerHidePricePayload,
} from "../interfaces/customers.types";

// Action Types
export enum CustomerActionTypes {
  GET_CUSTOMERS = "[Customers] GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS = "[Customers] GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAIL = "[Customers] GET_CUSTOMERS_FAIL",

  GET_CUSTOMER = "[Customers] GET_CUSTOMER",
  GET_CUSTOMER_SUCCESS = "[Customers] GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAIL = "[Customers] GET_CUSTOMER_FAIL",

  GET_CUSTOMER_GROUPS = "[Customers] GET_CUSTOMER_GROUPS",
  GET_CUSTOMER_GROUPS_SUCCESS = "[Customers] GET_CUSTOMER_GROUPS_SUCCESS",
  GET_CUSTOMER_GROUPS_FAIL = "[Customers] GET_CUSTOMER_GROUPS_FAIL",

  EXPORT_CUSTOMERS = "[Customers] EXPORT_CUSTOMERS",
  EXPORT_CUSTOMERS_SUCCESS = "[Customers] EXPORT_CUSTOMERS_SUCCESS",
  EXPORT_CUSTOMERS_FAIL = "[Customers] EXPORT_CUSTOMERS_FAIL",

  CREATE_ADDRESS = "[Customers] CREATE_ADDRESS",
  CREATE_ADDRESS_SUCCESS = "[Customers] CREATE_ADDRESS_SUCCESS",
  CREATE_ADDRESS_FAIL = "[Customers] CREATE_ADDRESS_FAIL",

  PAUSE_OR_RESUME_CUSTOMER = "[Customers] PAUSE_OR_RESUME_CUSTOMER",
  PAUSE_OR_RESUME_CUSTOMER_SUCCESS = "[Customers] PAUSE_OR_RESUME_CUSTOMER_SUCCESS",
  PAUSE_OR_RESUME_CUSTOMER_FAIL = "[Customers] PAUSE_OR_RESUME_CUSTOMER_FAIL",

  IMPORT_HORECA_CODE = "[Customers] IMPORT_HORECA_CODE",
  IMPORT_HORECA_CODE_SUCCESS = "[Customers] IMPORT_HORECA_CODE_SUCCESS",
  IMPORT_HORECA_CODE_FAIL = "[Customers] IMPORT_HORECA_CODE_FAIL",

  EXPORT_QR_CODES = "[Customers] EXPORT_QR_CODES",
  EXPORT_QR_CODES_SUCCESS = "[Customers] EXPORT_QR_CODES_SUCCESS",
  EXPORT_QR_CODES_FAIL = "[Customers] EXPORT_QR_CODES_FAIL",

  UPDATE_CUSTOMER_HIDE_PRICE = "[Customers] UPDATE_CUSTOMER_HIDE_PRICE",
  UPDATE_CUSTOMER_HIDE_PRICE_SUCCESS = "[Customers] UPDATE_CUSTOMER_HIDE_PRICE_SUCCESS",
  UPDATE_CUSTOMER_HIDE_PRICE_FAIL = "[Customers] UPDATE_CUSTOMER_HIDE_PRICE_FAIL",

  IMPORT_HIDE_PRICE = "[Customers] IMPORT_HIDE_PRICE",
  IMPORT_HIDE_PRICE_SUCCESS = "[Customers] IMPORT_HIDE_PRICE_SUCCESS",
  IMPORT_HIDE_PRICE_FAIL = "[Customers] IMPORT_HIDE_PRICE_FAIL",
  IMPORT_HIDE_PRICE_CLOSE_MSG_MODAL = "[Customers] IMPORT_HIDE_PRICE_CLOSE_MSG_MODAL",

  GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY = "[Customers] GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY",
  GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_SUCCESS = "[Customers] GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_SUCCESS",
  GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_FAIL = "[Customers] GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_FAIL",

  GET_HIDE_PRICE_FILE = "[Customers] GET_HIDE_PRICE_FILE",
  GET_HIDE_PRICE_FILE_SUCCESS = "[Customers] GET_HIDE_PRICE_FILE_SUCCESS",
  GET_HIDE_PRICE_FILE_FAIL = "[Customers] GET_HIDE_PRICE_FILE_FAIL",

  RESET_GET_HIDE_PRICE_FILE_STATE = "[Customers] RESET_GET_HIDE_PRICE_FILE_STATE",
}

export const getCustomers = (payload: IGetCustomersPayload): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMERS,
  payload,
});

export const getCustomersSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMERS_SUCCESS,
  payload,
});

export const getCustomersFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMERS_FAIL,
  error,
});

export const getCustomerHidePriceUploadHistory = (
  payload?: IGetCustomerHidePriceUploadHistoryPayload
): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY,
  payload,
});

export const getCustomerHidePriceUploadHistorySuccess = (
  payload?: any
): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_SUCCESS,
  payload,
});

export const getCustomerHidePriceUploadHistoryFail = (
  error: any
): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_FAIL,
  error,
});

export const getCustomer = (payload: IGetCustomerPayload): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER,
  payload,
});

export const getCustomerSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_SUCCESS,
  payload,
});

export const getCustomerFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_FAIL,
  error,
});

// Get customer groups
export const getCustomerGroups = (payload: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_GROUPS,
  payload,
});

export const getCustomerGroupsSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_GROUPS_SUCCESS,
  payload,
});

export const getCustomerGroupsFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.GET_CUSTOMER_GROUPS_FAIL,
  error,
});

export const exportCustomers = (
  payload: IGetCustomersPayload
): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_CUSTOMERS,
  payload,
});

export const exportCustomersSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_CUSTOMERS_SUCCESS,
  payload,
});

export const exportCustomersFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_CUSTOMERS_FAIL,
  error,
});

export const pauseOrResumeCustomers = (
  payload: IPauseOrResumeCustomerPayload
): IAction<any> => ({
  type: CustomerActionTypes.PAUSE_OR_RESUME_CUSTOMER,
  payload,
});

export const pauseOrResumeCustomersSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.PAUSE_OR_RESUME_CUSTOMER_SUCCESS,
  payload,
});

export const pauseOrResumeCustomersFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.PAUSE_OR_RESUME_CUSTOMER_FAIL,
  error,
});

export const updateCustomerHidePrice = (
  payload: IUpdateCustomerHidePricePayload
): IAction<IUpdateCustomerHidePricePayload> => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE,
  payload,
});

export const updateCustomerHidePriceSuccess = (
  payload?: any
): IAction<any> => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE_SUCCESS,
  payload,
});

export const updateCustomerHidePriceFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE_FAIL,
  error,
});

export const createAddress = (payload: IGetCustomersPayload): IAction<any> => ({
  type: CustomerActionTypes.CREATE_ADDRESS,
  payload,
});

export const createAddressSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.CREATE_ADDRESS_SUCCESS,
  payload,
});

export const createAddressFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.CREATE_ADDRESS_FAIL,
  error,
});

export const importHorecaInformation = (payload: any): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HORECA_CODE,
  payload,
});

export const importHorecaInformationSuccess = (
  payload?: any
): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HORECA_CODE_SUCCESS,
  payload,
});

export const importHorecaInformationFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HORECA_CODE_FAIL,
  error,
});

export const exportQRCodes = (payload: IExportQRCodePayload): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_QR_CODES,
  payload,
});

export const exportQRCodesSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_QR_CODES_SUCCESS,
  payload,
});

export const exportQRCodesFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.EXPORT_QR_CODES_FAIL,
  error,
});

export const importHidePrice = (payload: any): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HIDE_PRICE,
  payload,
});

export const importHidePriceSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HIDE_PRICE_SUCCESS,
  payload,
});

export const importHidePriceFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HIDE_PRICE_FAIL,
  error,
});

export const importHidePrice_CloseMsgModal = (): IAction<any> => ({
  type: CustomerActionTypes.IMPORT_HIDE_PRICE_CLOSE_MSG_MODAL,
});

export const getHidePriceFile = (payload: string): IAction<any> => ({
  type: CustomerActionTypes.GET_HIDE_PRICE_FILE,
  payload,
});

export const getHidePriceFileSuccess = (payload?: any): IAction<any> => ({
  type: CustomerActionTypes.GET_HIDE_PRICE_FILE_SUCCESS,
  payload,
});

export const getHidePriceFileFail = (error: any): IAction<any> => ({
  type: CustomerActionTypes.GET_HIDE_PRICE_FILE_FAIL,
  error,
});

export const resetGetHidePriceFileState = (): IAction<any> => ({
  type: CustomerActionTypes.RESET_GET_HIDE_PRICE_FILE_STATE,
});
