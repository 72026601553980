import Type from '../../type';
import {getApiController} from '../../resource';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { IGetUserPayload } from '../../../../../presentation/redux/actions/interfaces/users.types';
import { IGetCustomerGroupsPayload, IGetCustomersPayload, ICreateAddressPayload, IExportQRCodePayload, IGetCustomerHidePriceUploadHistoryPayload, ImportDeliveryHolidaysHistoryPayload } from '../../../../../presentation/redux/actions/interfaces/customers.types';

const Login = (storeView?: string): IResource => ({
    Type: Type.Login,
    Path: `${getApiController(storeView)}/identity/v1.0/Login`
});

const SetDefaultPassword = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/api/v1/users/set-default-users-password`
});


const UserProfile = (storeView?: string, userId: string = ''): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/Detail?userId=${userId}`
});


const ChangePassword = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/api/v1/users/forgot-password`
});

const CreateUser = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/api/v1/users/create-user`
});



const CustomerGroup = (query: string, storeView?: string): IResource => ({
    Type: Type.Admin,
    Path: `${getApiController(storeView)}/customerGroups/search?${query}`
});


const CustomerGroupDetail = (groupId: any, storeView?: string): IResource => ({
    Type: Type.Admin,
    Path: `${getApiController(storeView)}/customerGroups/?${groupId}`
});

const GetUsers = (payload: IGetUserPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0?keyword=${payload.keyword}&role=${payload.role}&status=0&page=${payload.page}&pageSize=${payload.pageSize}`
});


const GetCustomers = (payload: IGetCustomersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0?page=${payload.page}&pageSize=${payload.pageSize}&sakayaId=${payload.sakayaId ?? ''}&keyword=${payload.keyword ?? ''}&hasCustomerHoliday=${payload.hasCustomerHoliday || ''}&horecaCode=${payload.horecaCode ?? ''}&IsPersonalAccount=false&isActive=${payload.isActive ?? false}`
});

const GetCustomerHidePriceUploadHistory = (payload: IGetCustomerHidePriceUploadHistoryPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportCustomerHidePriceHistory?Page=${payload.page}&PageSize=${payload.pageSize}&StartDate=${payload.startDate}&EndDate=${payload.endDate}`
});

const GetCustomer = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/Detail?includeAddresses=true&customerId=${id}`
});


const GetUser = (id: number, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/api/users/${id}`
});

const DeleteUser = (id: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/Delete?userId=${id}`
});

const UpdateUser = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/Update`
});

const AddUser = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/Create`
});

const SakayaInfo = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/GetSakaya`
});

const UpdateDeliveryInfo = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/UpdateDeliveryInfo`
});

const RemoveCustomerHoliday = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/RemoveCustomerHoliday?customerId=${id}`,
});

const UpdateCustomerHoliday = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/UpdateCustomerHoliday`,
});

const ImportDeliveryHoliday = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/ImportDeliveryHoliday`,
});

const ImportDeliveryHolidaysHistory = ({ keyword, page, pageSize, startDate, endDate }: ImportDeliveryHolidaysHistoryPayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/customers/v1.0/ImportDeliveryHolidaysHistory?&ignoreLogs=true`;
    if (keyword) Path += `&keyword=${keyword}`;
    if (startDate) Path += `&startDate=${startDate}`;
    if (endDate) Path += `&endDate=${endDate}`;
    Path += `&page=${page}&pageSize=${pageSize}`;

    return {
        Type: Type.Customer,
        Path,
    };
};

const UpdateSakayaInfo = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/users/v1.0/UpdateSakaya`
});

const UpdatePassword = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/identity/v1.0/UpdatePassword`
});

const ResetPassword = (storeView?: string): IResource => ({
    Type: Type.Login,
    Path: `${getApiController(storeView)}/identity/v1.0/ResetPassword`
});

const GetCustomerGroups = (payload:IGetCustomerGroupsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/product/v1.0/GetCustomerGroups?keyword=${payload.keyword}`
});

const ExportCustomers = (payload: IGetCustomersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/ExportCustomers?keyword=${payload.keyword}`
});

const PauseOrResumeCustomer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/PauseOrRestartCustomer`
});

const CreateAddress = (payload: ICreateAddressPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/CreateAddress?customerId=${payload.customerId}&sakayaId=${payload.sakayaId}&postalCode=${payload.postalCode}&prefectureId=${payload.prefectureId}&areaId=${payload.areaId}&buildingName=${payload.buildingName}&phone=${payload.phone}&isCreateAddressOnParent=true`
});

const ImportHorecaInformation = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/ImportHorecaInformation`
});

const ImportHidePrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ImportCustomerHidePrice`
})

const ExportQRCodes = (payload:IExportQRCodePayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/ExportQRCodes?sakayaId=${payload.sakayaId}&horecaCodes=${payload.horecaCodes}`
});

const UpdateCustomerHidePrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/UpdateCustomerHidePriceStatus`
})

const GetCustomerHidePriceFile = (payload: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/GetFileImportPrice?fileName=${encodeURIComponent(payload)}`
})

const GetSakayas = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/customers/v1.0/GetSakayas`
})

export default {
    Login,
    GetUsers,
    AddUser,
    UpdateSakayaInfo,
    UpdatePassword,
    GetCustomers,
    GetCustomerGroups,
    CreateUser,
    GetUser,
    DeleteUser,
    ResetPassword,
    UserProfile,
    SakayaInfo,
    UpdateDeliveryInfo,
    RemoveCustomerHoliday,
    UpdateCustomerHoliday,
    ImportDeliveryHoliday,
    ImportDeliveryHolidaysHistory,
    ChangePassword,
    CustomerGroup,
    CustomerGroupDetail,
    SetDefaultPassword,
    UpdateUser,
    GetCustomer,
    ExportCustomers,
    PauseOrResumeCustomer,
    CreateAddress,
    ImportHorecaInformation,
    ExportQRCodes,
    UpdateCustomerHidePrice,
    ImportHidePrice,
    GetCustomerHidePriceUploadHistory,
    GetCustomerHidePriceFile,
    GetSakayas
};

