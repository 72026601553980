import { ISearchBrands, ISearchNotifications } from '../../../presentation/redux/actions/interfaces/notification.types';
import NotificationCloud from './cloud';
import NotificationStorage from './storage';

export default class NotificationRepository {
    public saveNotificationRepository = (payload: any) => {
        return NotificationCloud.saveNotification(payload);
    };

    public savePartialNotificationRepository = (payload: any) => {
        return NotificationCloud.savePartialNotification(payload);
    };

    public registerPriceChangesRepository = (payload: any) => {
        return NotificationCloud.registerPriceChanges(payload);
    };

    public pushNotificationRepository = (payload: any) => {
        return NotificationCloud.pushNotification(payload);
    };

    public searchBrandsRepository = (payload: ISearchBrands) => {
        return NotificationCloud.searchBrands(payload);
    };

    public searchBannersRepository = (payload: ISearchBrands) => {
        return NotificationCloud.SearchBanners(payload);
    };

    public searchNotificationsRepository = (payload: ISearchNotifications) => {
        return NotificationCloud.searchNotifications(payload);
    };

    public searchPriceChangesRepository = (payload: ISearchNotifications) => {
        return NotificationCloud.searchPriceChanges(payload);
    };

    public searchTagsRepository = (payload: any) => {
        return NotificationCloud.searchTags(payload);
    };

    public deleteTagsRepository = (payload: any) => {
        return NotificationCloud.deleteTags(payload);
    };

    public getCategoriesRepository = () => {
        return NotificationCloud.getCategories();
    };

    public AddTagForSakayaRepository = (payload: any) => {
        return NotificationCloud.addTagForSakaya(payload);
    };

    public updateTagForSakayaRepository = (payload: any) => {
        return NotificationCloud.updateTagForSakaya(payload);
    };

    public addTagForCustomerRepository = (payload: any) => {
        return NotificationCloud.addTagForCustomer(payload);
    };
    
    public deleteTagForCustomerRepository = (payload: any) => {
        return NotificationCloud.deleteTagForCustomer(payload);
    };

    public importCustomerTagsRepository = (payload: any) => {
        return NotificationCloud.importCustomerTags(payload);
    };

    public populateAddressRepository = (payload: any) => {
        return NotificationCloud.populateAddress(payload);
    };

    public getOrderOfProductRepository = (payload: any) => {
        return NotificationCloud.getOrderOfProduct(payload);
    };
    
    public getPriceChangeLinesRepository = (payload: any) => {
        return NotificationCloud.getPriceChangeLines(payload);
    };

    public uploadPriceNotificationRepository = (payload: any) => {
        return NotificationCloud.uploadPriceNotification(payload);
    };

    public importNotificationHistoryRepository = (payload: any) => {
        return NotificationCloud.importNotificationHistory(payload);
    };

    public downloadDraftToFileRepository = (payload: any) => {
        return NotificationCloud.downloadDraftToFile(payload);
    };

    public getDraftRecordRepository = (payload: any) => {
        return NotificationCloud.getDraftRecord(payload);
    };

    public getNotificationDetailRepository = (payload: any) => {
        return NotificationCloud.getNotificationDetail(payload);
    };

    public deleteDraftRecordRepository = (payload: any) => {
        return NotificationCloud.deleteDraftRecord(payload);
    };

    public deleteNotificationsRepository = (payload: any) => {
        return NotificationCloud.deleteNotifications(payload);
    };

    public updateNotificationStatusRepository = (payload: any) => {
        return NotificationCloud.updateNotificationStatus(payload);
    };

    public searchDiscontinuedRepository = (payload: any) => {
        return NotificationCloud.searchDiscontinued(payload);
    };

    public searchReadyDiscontinuedRepository = (payload: any) => {
        return NotificationCloud.searchReadyDiscontinued(payload);
    };

    public getDiscontinuedProductsRepository = (payload: any) => {
        return NotificationCloud.getDiscontinuedProducts(payload);
    };

    public historyDiscontinuedProductsRepository = (payload: any) => {
        return NotificationCloud.historyDiscontinuedProducts(payload);
    };

    public countWarningProductRepository = (payload: any) => {
        return NotificationCloud.countWarningProduct(payload);
    };

    public createDiscontinuedProductsRepository = (payload: any) => {
        return NotificationCloud.createDiscontinuedProducts(payload);
    };

    public uploadDiscontinuedProductRepository = (payload: any) => {
        return NotificationCloud.uploadDiscontinuedProduct(payload);
    };

    public updateDiscontinueLineRepository = (payload: any) => {
        return NotificationCloud.updateDiscontinueLine(payload);
    };  
    
    public deleteDiscontinueLineRepository = (payload: any) => {
        return NotificationCloud.deleteDiscontinueLine(payload);
    };

    public savePromotionRepository = (payload: any) => {
        return NotificationCloud.savePromotion(payload);
	};
	
    public calculateAnnouncementTargetRepository = (payload: any) => {
        return NotificationCloud.calculateAnnouncementTarget(payload);
	};

    public downloadDiscontinueLineRepository = (payload: any) => {
        return NotificationCloud.downloadDiscontinueLine(payload);
    };

    public searchSegmentsRepository = (payload: any) => {
        return NotificationCloud.searchSegments(payload);
    };

    public addSegmentRepository = (payload: any) => {
        return NotificationCloud.addSegment(payload);
    };

    public calculateAudienceSizeRepository = (payload: any) => {
        return NotificationCloud.calculateAudienceSize(payload);
    };

    public deleteSegmentRepository = (payload: any) => {
        return NotificationCloud.deleteSegment(payload);
    };
}
